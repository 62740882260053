<script setup>
const props = defineProps({
  part: {
    type: Object,
    required: true,
  },
});

const localePath = useLocalePath()
</script>

<template>
  <UPageCard :to="localePath({ name: 'parts-uuid', params: { uuid: props.part.uuid } })" :ui="{
    header: { padding: '' },
    body: { base: 'h-full flex flex-col place-content-between', padding: 'sm:py-2 sm:px-4 py-2 px-4' },
  }">
    <div class="mb-4">
      <!-- <UBreadcrumb v-if="part.category" :links="buildCategoryBreadcrumb(part, localePath)" /> -->
      <UBreadcrumb v-if="part.make" :links="buildAutoBreadcrumb(part, localePath)" />
      <div class="flex items-center flex-wrap gap-x-2">
        <p class="m-0">
          <template v-if="part.vin">
            {{ part.vin }}
          </template>
          <template v-else>
            <span class="m-0 text-gray-500">Fara VIN</span>
          </template>
        </p>
        <UButton v-if="part.variant" class="-ml-2"
          :to="localePath({ name: 'parts-search-requests', query: { make_id: part.make.id, model_id: part.model.id, generation_id: part.generation.id, variant_id: part.variant.id } })"
          variant="ghost">
          <!-- <UButton v-if="part.variant" class="-ml-2"
          :to="localePath(`parts-search-requests?make_id=${part.make.id}&model_id=${part.model.id}&generation_id=${part.generation.id}&variant_id=${part.variant.id}`)"
          variant="ghost"> -->
          {{ part.variant.name }}
        </UButton>
      </div>
    </div>

    <p class="m-0">
      <template v-if="part.description">
        {{ part.description }}
      </template>
      <template v-else>
        <span class="m-0 text-gray-500">Fara descriere</span>
      </template>
    </p>
  </UPageCard>
</template>