<script setup lang="ts">
const emit = defineEmits(["update:modelValue"]);
const fileRef = ref<{ input: HTMLInputElement }>();

const props = defineProps({
  modelValue: {
    type: String,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  button: {
    type: String,
    default: "Selecteaza",
  },
  camera: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: Boolean,
    default: false,
  },
  help: {
    type: Boolean,
    default: false,
  },
  deletable: {
    type: Boolean,
    default: true,
  },
});

const acceptFormat = computed(() => {
  return props.camera ? `image/*;capture=camera"` : '.png, .jpg, .jpeg, .webp';
});

function readFile(event: FileList) {
  // event type seems to have changed - was: Event, now: FileList
  // const target = event.target as HTMLInputElement;
  // const targetFile = target.files?.[0];
  const targetFile = event[0];
  if (targetFile) {
    const reader = new FileReader();
    reader.onload = (e) => {
      emit("update:modelValue", {
        data: e.target?.result,
        ext: targetFile.name.split(".").pop(),
      });
    };
    reader.readAsDataURL(targetFile);
  }
}

function onFileClick() {
  fileRef.value?.input.click();
}
function deleteImage() {
  emit("update:modelValue", "");
}
</script>

<template>
  <div class="relative w-full">
    <UFormGroup name="image" :label="props.label" class="flex flex-col gap-2"
      :help="props.help ? 'PNG, JPG, JPEG, sau WEBP. 5MB Max.' : ''">
      <div class="flex items-center gap-2">
        <ImagePreview v-if="props.modelValue.data ? props.modelValue.data : props.modelValue"
          :image="props.modelValue.data ? props.modelValue.data : props.modelValue" previewSize="10" whiteBackground />
        <UAvatar v-else />

        <div class="flex flex-row items-center gap-2">
          <UButton class="h-fit" :label="button" color="white" size="md" @click="onFileClick" />
          <UButton v-if="props.modelValue.data ? props.modelValue.data : props.modelValue && props.deletable" color="red"
            icon="i-heroicons-minus" size="xs" @click="deleteImage" />

          <UInput ref="fileRef" type="file" class="hidden" :accept="acceptFormat" :name="props.name"
            @click="onFileClick" @change="readFile" />
        </div>
      </div>
    </UFormGroup>

    <UPopover v-if="hint" class="absolute top-0 right-0" mode="click" trigger="hover" placement="top-end">
      <UBadge color="primary" label="Ajutor" variant="subtle">
        Indiciu&nbsp;
        <Icon class="w-4 h-4 text-primary" name="i-material-symbols-right-click-rounded" />
      </UBadge>

      <template #panel>
        <div class="p-2">
          Din motive de performanta, imaginile cu dimensiuni
          <br />
          mari pot sa nu fie afisate dupa ce le selectezi.
        </div>
      </template>
    </UPopover>
  </div>
</template>
