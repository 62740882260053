<script setup lang="ts">
const emit = defineEmits(["update:make", "update:model", "update:generation", "update:variant"]);

const props = defineProps({
  grid: {
    type: Boolean,
    default: false,
  },
  row: {
    type: Boolean,
    default: false,
  },
  make: {
    type: Number,
  },
  model: {
    type: Number,
  },
  generation: {
    type: Number,
  },
  variant: {
    type: Number,
  },
  reloadTrigger: {
    type: Boolean,
    default: false,
  },
});

const loading = ref(true);
const make = ref(props.make)
const model = ref(props.model)
const generation = ref(props.generation)
const variant = ref(props.variant)

watch(() => make.value, () => emit("update:make", make.value));
watch(() => model.value, () => emit("update:model", model.value));
watch(() => generation.value, () => emit("update:generation", generation.value));
watch(() => variant.value, () => emit("update:variant", variant.value));

watch(() => props.reloadTrigger, () => {
  make.value = props.make;
  model.value = props.model;
  generation.value = props.generation;
  variant.value = props.variant;
  fetchAutodataAll()
});

const makes = ref([]);
const models = ref([]);
const generations = ref([]);
const variants = ref([]);

if (Object.values({ make, model, generation, variant }).some((v) => v.value)) {
  fetchAutodataAll()
} else {
  fetchAutoDataSpec('make')
}

function fetchAutoDataSpec(dataType: string) {
  loading.value = true;
  let dataId = 0;

  if (dataType == 'make') {
    make.value = null;
    model.value = null;
    generation.value = null;
    models.value = [];
    generations.value = [];
    variants.value = [];
  } else if (dataType == 'model') {
    dataId = make.value;

    model.value = null;
    generation.value = null;
    models.value = [];
    generations.value = [];
    variants.value = [];
  } else if (dataType == 'generation') {
    dataId = model.value;

    generation.value = null;
    generations.value = [];
    variants.value = [];
  } else if (dataType == 'variant') {
    dataId = generation.value;

    variants.value = [];
  }

  useApi("GET", `/v1/autodata/${dataType}/${dataId}`).then((res: any) => {
    if (res.data) {
      if (dataType == 'make') {
        makes.value = res.data;
      } else if (dataType == 'model') {
        models.value = res.data;
      } else if (dataType == 'generation') {
        generations.value = res.data.map((generation: any) => {
          return {
            ...generation,
            label: generationFormatter(generation),
          };
        });
      } else if (dataType == 'variant') {
        variants.value = res.data;
      }
    }
  }).finally(() => {
    loading.value = false;
  });
}
function fetchAutodataAll() {
  useApi("POST", `/v1/autodata/many`, {
    data: {
      make_id: make.value,
      model_id: model.value,
      generation_id: generation.value,
      variant_id: variant.value,
    }
  }).then((res: any) => {
    if (res.data) {
      if (res.data.makes) {
        makes.value = res.data.makes;
      }
      if (res.data.models) {
        models.value = res.data.models;
      }
      if (res.data.generations) {
        generations.value = res.data.generations.map((generation: any) => {
          return {
            ...generation,
            label: generationFormatter(generation),
          };
        });
      }
      if (res.data.variants) {
        variants.value = res.data.variants;
      }
    }
  }).finally(() => {
    loading.value = false;
  });
}
</script>

<template>
  <div :class="{ 'flex flex-col gap-2': grid, 'flex flex-col md:flex-row gap-2': row }">
    <div :class="{ 'grid grid-cols-2 gap-2': grid, 'w-full md:w-1/2 flex flex-col md:flex-row gap-2': row }">
      <USelectMenu :class="{ 'w-full md:w-1/2': row }" v-model="make" placeholder="Marca" :options="makes"
        option-attribute="name" value-attribute="id" :loading="!make && loading" @change="fetchAutoDataSpec('model')"
        searchable />

      <USelectMenu :class="{ 'w-full md:w-1/2': row }" v-model="model" placeholder="Model" :options="models"
        option-attribute="name" value-attribute="id" :disabled="!Boolean(make)" :loading="!model && loading"
        @change="fetchAutoDataSpec('generation')" searchable />
    </div>

    <USelectMenu :class="{ 'w-full md:w-1/4': row }" v-model="generation" placeholder="Generatie" :options="generations"
      value-attribute="id" :disabled="!Boolean(model)" :loading="!generation && loading"
      @change="fetchAutoDataSpec('variant')" searchable />

    <USelectMenu :class="{ 'w-full md:w-1/4': row }" v-model="variant" placeholder="Varianta" :options="variants"
      option-attribute="name" value-attribute="id" :disabled="!Boolean(generation)" :loading="!variant && loading"
      searchable />
  </div>
</template>
