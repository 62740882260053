<script setup lang="ts">
const props = defineProps({
  image: {
    type: String,
    default: "",
  },
  avatar: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'popover',
  },
  whiteBackground: {
    type: Boolean,
    default: false,
  },
  previewSize: {
    type: String,
    default: "8",
  },
  alt: {
    type: String,
    default: "",
  },
});

const modalOpen = ref(false);

const size = computed(() => {
  // return `h-${props.previewSize} w-${props.previewSize} `;
  return {
    [`h-${props.previewSize}`]: true,
    [`w-${props.previewSize}`]: true,
  };
});
</script>

<template>
  <div>
    <UAvatar v-if="!image" :alt="avatar" :class="size" />

    <UPopover v-else-if="type === 'popover'" class="flex rounded" :class="{ ...size }" mode="click">
      <NuxtImg :src="image" :class="{ 'bg-white p-1': whiteBackground, ...size }"
        class="object-cover rounded cursor-pointer" :alt="alt" />

      <template #panel>
        <NuxtImg :src="image" class="w-full h-full max-h-96 max-w-96 p-2 rounded" :alt="alt" />
      </template>
    </UPopover>

    <div v-else-if="type === 'modal'" class="flex rounded cursor-pointer" :class="{ ...size }">
      <NuxtImg :src="image" :class="{ 'bg-white p-1': whiteBackground, ...size }"
        class="object-cover rounded cursor-pointer flex rounded" :alt="alt" @click="modalOpen = true" />
    </div>

    <UModal v-model="modalOpen">
      <NuxtImg :src="image" class="h-full w-full p-2 rounded" :alt="alt" />
    </UModal>
  </div>
</template>
