<script async setup>
const localePath = useLocalePath();
const recentparts = ref([]);
await fetchRecent()

async function fetchRecent() {
  await useApi("GET", `/v1/parts/recent`)
    .then((res) => {
      if (res) {
        recentparts.value = res.data;
      }
    })
}
</script>

<template>
  <UPageGrid>
    <UPageCard class="shadow-none" title="Solicitari recente" description="Ultimele solicitari pentru piese auto."
      icon="i-heroicons-arrow-turn-right-down" :ui="{ ring: 'ring-0' }" />
    <PartsPreview v-for="(part, index) in recentparts" :part="part" :key="index" />

    <UPageCard title="Vezi toate" description="Gaseste solicitari de piese pentru un autoturism anume."
      icon="i-material-symbols-all-match-outline-rounded" :to="localePath('parts-search-requests')" />
  </UPageGrid>
</template>
