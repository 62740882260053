<script async setup lang="ts">
const props = defineProps({
  landing: {
    type: Boolean,
    default: false,
  },
});

const localePath = useLocalePath();
const toast = useToast();

const submitting = ref(false);
const isOpen = ref(false);

const defaultState = ref({
  make_id: null,
  model_id: null,
  generation_id: null,
  variant_id: null,
  category_id: null,
  subcategory_id: null,
  phone: "",
  description: "",
  images: [{ image: '' }],
  vin: "",
  email: "",
})

const state = ref(JSON.parse(JSON.stringify(defaultState.value)));
watch(
  () => defaultState.value,
  (value) => {
    state.value = JSON.parse(JSON.stringify(value));
    if (!value) {
      state.value = defaultState.value;
    }
  }
);

const loading = ref(true);

const validate = (state: any) => {
  const errors = [];
  // if (!state.category_id)
  //   errors.push({ path: "category_id", message: "Categoria piesei este obligatorie" });
  if (!state.make_id)
    errors.push({ path: "make_id", message: "Marca autoturismului este obligatorie" });
  if (!state.phone)
    errors.push({ path: "phone", message: "Numarul de telefon este obligatoriu" });
  return errors;
};

function submit(event: any) {
  submitting.value = true;
  useApi("POST", "/v1/parts/inquire", { data: event.data }).then(
    (res: any) => {
      toast.add({
        title: "Solicitarea dvs. a fost trimisa cu succes! Veti fi contactat in cel mai scurt timp.",
        icon: "i-heroicons-outline-check-circle",
        color: "primary",
      });
      closeModal();
    }
  );
}
function closeModal() {
  submitting.value = false;
  isOpen.value = false;
  state.value = JSON.parse(JSON.stringify(defaultState.value));
}
</script>

<template>
  <ULandingSection title="Cauti o piesa auto? Contacteaza-ne!"
    description="Plaseaza o solicitare pentru piesa pe care o cauti si noi te vom contacta la scurt timp."
    :ui="{ wrapper: 'pt-4 mt-0 md:mt-12 sm:py-12', container: 'gap-8 md:gap-8' }">

    <UPageGrid class="grid-cols-1 xl:grid-cols-2">
      <ULandingCard class="cursor-pointer" title="Cauti o piesa auto?"
        description="Plaseaza o solicitare pentru piesa pe care o cauti si vei fi contactat la scurt timp."
        icon="i-material-symbols-category-search-outline-rounded" @click="isOpen = true" />
      <div class="grid grid-rows-2 gap-4">
        <UButton class="min-h-[90px]" :to="localePath('parts')" variant="soft" label="Piese frecvent cautate"
          icon="i-material-symbols-all-match-outline-rounded" />
        <UButton class="min-h-[90px]" :to="localePath('parts-search-requests')" variant="soft"
          label="Ai o piesa auto spre vanzare? Cauta solicitari" icon="i-heroicons-magnifying-glass" />
      </div>
    </UPageGrid>

    <PartsRecent />

    <UDashboardModal v-model="isOpen" title="Solicit Piesa">
      <UForm class="space-y-4" :state="state" :validate-on="['submit']" :validate="validate" @submit="submit">
        <!-- <UFormGroup name="category_id" label="Detalii piesa" required>
          <SelectPartAll v-model:category="state.category_id" v-model:subcategory="state.subcategory_id" />
        </UFormGroup> -->

        <UFormGroup name="make_id" label="Detalii autoturism" required>
          <SelectAutoAll v-model:make="state.make_id" v-model:model="state.model_id"
            v-model:generation="state.generation_id" v-model:variant="state.variant_id" grid />
        </UFormGroup>

        <UFormGroup name="phone" label="Telefon" required>
          <UInput v-model="state.phone" placeholder="070 000 000" />
        </UFormGroup>

        <UFormGroup name="description" label="Descriere piesa">
          <UTextarea v-model="state.description" placeholder="Denumirea piesei / Numarul Piesei / Descriere" />
        </UFormGroup>

        <UFormGroup name="vin" label="Cod VIN">
          <UInput v-model="state.vin" placeholder="1HGCM82633A123456" />
        </UFormGroup>

        <InputImage v-model="state.images[0].image" label="Poza piesei" name="image" hint />
        <!-- <InputImagesMany v-model="state.images" label="Pozele piesei" /> -->

        <!-- <UFormGroup name="email" label="Email">
          <UInput v-model="state.email" placeholder="exemplu@gmail.com" />
        </UFormGroup> -->

        <div class="flex justify-end gap-3">
          <UButton variant="ghost" color="gray" label="Inapoi" @click="closeModal" />
          <UButton type="submit" color="black" label="Expediaza" :loading="submitting" />
        </div>
      </UForm>
    </UDashboardModal>
  </ULandingSection>
</template>
